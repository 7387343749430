
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  /*     IonImg,
   */ IonItem,
  /*     IonLabel, */
  IonMenuButton,
  IonPage,
  IonRow,
  /*     IonSegment,
    IonSegmentButton, */
  IonText,
  IonTitle,
  IonToolbar,
  IonThumbnail,
} from "@ionic/vue";
import {
  pin,
  wine,
  warning,
  walk,
  rocket,
  logoTwitter,
  logoFacebook,
  logoInstagram,
  newspaper,
  heartOutline,
  heart,
  notifications,
  shareSocial,
} from "ionicons/icons";

export default {
  name: "NewsList",

  components: {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    /*         IonImg,
     */ IonItem,
    /*         IonLabel, */
    IonMenuButton,
    IonPage,
    IonRow,
    /*         IonSegment,
        IonSegmentButton, */
    IonText,
    IonTitle,
    IonToolbar,
    IonThumbnail,
  },
  methods: {
    segmentChanged(ev: any): any {
      console.log("Segment changed", ev);
    },
  },
  setup(): any {
    return {
      pin,
      wine,
      warning,
      walk,
      rocket,
      logoTwitter,
      logoFacebook,
      logoInstagram,
      newspaper,
      heartOutline,
      heart,
      notifications,
      shareSocial,
    };
  },
};
